import React, { useRef } from 'react';
import styles from './FinalEpq.module.scss';
import AddressForm from '~/components/shared/AddressFormBrand';
import Typography from '~/components/shared/Typography';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import { WhiteHughesnetLogo } from '~/components/icons';
import Button from '~/components/shared/Button';

const BASE_DATA = {
  addressCTACopy: 'Shop plans',
  addressLabelColor: 'white',
  addressLabelCopy: 'Enter your address to check available plans',
  addressPlaceHolderCopy: 'Enter your address',
  backgroundImage: 'final-epq-banner-hills',
  headline: 'Find out what NEW Hughesnet plan could be right for you',
  headlineTextColor: 'white',
  mapPinIconColor: '#154B8E',
  subhead:
    'Our plans service across the nation so you can stay connected from wherever you live.',
  subheadTextColor: 'white',
  backgroundColor: '#005dac',
  isEpq: true,
  hasImage: false,
  secondaryCTACopy: 'Learn More',
  epqCopy: 'Shop Plans',
};

export default function FinalEpq(metadata) {
  const data = { ...BASE_DATA, ...metadata };
  const {
    headlineTextColor,
    subheadTextColor,
    headline,
    subhead,
    backgroundImage,
    hasImage,
    backgroundColor,
    isEpq,
    epqCopy,
    secondaryCTACopy,
  } = data;

  //Refs used for eventing & animation
  const finalEpqSection = useRef();

  useIntersectionObserver(
    [finalEpqSection],
    () =>
      fireElementViewedEvent(
        'final epq rebrand',
        'final epq rebrand section',
        'final epq rebrand',
        headline
      ),
    undefined,
    { threshold: 0.75 }
  );

  const bgImage = backgroundImage
    ? `/images/section/${backgroundImage}.jpg`
    : `/images/section/final-epq-banner.jpg`;

  const isPositionRight = backgroundImage == 'final-epq-banner-hills';

  return (
    <div
      ref={finalEpqSection}
      className={`${styles.container}`}
      style={{ backgroundColor: backgroundColor }}
    >
      {backgroundImage !== 'none' && (
        <img
          className={`${styles.backgroundImage} ${
            isPositionRight
              ? styles.backgroundPositionRight
              : styles.backgroundPositionCenter
          }`}
          src={bgImage}
          alt=""
        />
      )}
      <div className={('grid-container', styles.content)}>
        <div className={styles.headers}>
          {hasImage && <WhiteHughesnetLogo />}
          <Typography
            variant="h1"
            className={`${styles.heading} ${!hasImage && styles.marginTop}`}
          >
            <span
              dangerouslySetInnerHTML={{ __html: headline }}
              style={{ color: headlineTextColor }}
            />
          </Typography>
          <Typography variant="h4" className={styles.subheading}>
            <span
              dangerouslySetInnerHTML={{ __html: subhead }}
              style={{ color: subheadTextColor }}
            />
          </Typography>
        </div>
        {isEpq ? (
          <div className={styles.addressContainer}>
            <AddressForm metadata={data} isFinalEpq={true} />
          </div>
        ) : (
          <div className={styles.buttons}>
            <Button
              href="/order-online"
              tracking={{
                position: 'ACP BANNER EPQ',
                location: 'ACP BANNER',
                elementType: 'BUTTON',
                text: 'Check Availablity',
                actionOutcome: 'Redirect to Cart',
              }}
              fuse
              className={`${styles.epqBtn} ${styles.btn}`}
            >
              {epqCopy}
            </Button>
            <Button
              tracking={{
                position: 'ACP BANNER CTA',
                location: 'ACP BANNER',
                elementType: 'BUTTON',
                text: 'Call now',
                actionOutcome: 'Click to call',
              }}
              fuse
              className={`${styles.callNowCta} ${styles.btn}`}
            >
              {secondaryCTACopy}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
