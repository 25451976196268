import React from 'react';
import Head from 'next/head';
import { Preamp } from '@red-digital/bricks';
import { HeroSkeleton } from '~/components/shared/Skeleton';
import MirHero from '~/components/sections/MIR Hero';
import ACP_Banner from '~/components/sections/ACP Banner';
import WholeWifiModule from '~/components/sections/Whole Home WiFi';
import EducationModule from '~/components/sections/Education Module';
import Banner from '~/components/sections/US News';
import ValueProps from '~/components/sections/Value Props';
import FinalEpq from '~/components/sections/Final EPQ';

const Home = () => {
  return (
    <>
      <Head>
        <title>Hughesnet® Internet | 1-855-386-1643 | Satellite Internet</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Hughesnet® is America's #1 choice for satellite internet. Save big with Award-Winning Hughesnet packages. Plus Free standard installation at no extra cost!"
        />
      </Head>

      {/* Hero Placement */}
      <Preamp placement="4VdUdUM1j7gFHXes7NqVPF" skeleton={HeroSkeleton}>
        <MirHero />
      </Preamp>

      {/* Banner Below Hero Placement */}
      <Preamp placement="10P4eZlEej5Awmgo2YUGiC">
        <ValueProps />
      </Preamp>

      {/* Section 1 Placement */}
      <Preamp placement="2TvlZ5qJXwIaUwILfRrflF">
        <ACP_Banner />
      </Preamp>

      {/* Section 2 Placement */}
      <Preamp placement="6APp9Tgx9kgvtT9Jzirp35">
        <WholeWifiModule />
      </Preamp>

      {/* Section 3 Placement */}
      <Preamp placement="3ETuJls1gI4Mkd5hdbr6aL">
        <EducationModule />
      </Preamp>

      {/* Section 4 Placement */}
      <Preamp placement="1hEJvSU7FGLiN7OF21b6Sn">
        <Banner />
      </Preamp>

      {/* Section 5 Placement */}
      <Preamp placement="50oFaqrrR5LUqnMZrcVinK">
        <FinalEpq />
      </Preamp>

      {/* Section 6 Placement */}
      <Preamp placement="7FKlHEBWSnMJwN30vK4Dcb" />

      {/* Section 7 Placement */}
      <Preamp placement="1aNLmdEcqxidyMp6SwfjVj" />

      {/* Section 8 Placement */}
      <Preamp placement="5MSDldnvFD5Y0SgQbIGQNZ" />

      {/* Return Visitor Modal */}
      <Preamp placement="1WxHXlKAqe23e3dysil9KS" />
    </>
  );
};

export default Home;
